import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { ApplicationConfigService } from '../config/application-config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private applicationConfigService: ApplicationConfigService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const serverApiUrl = this.applicationConfigService.getEndpointPrefix();
    const serverApiUrl2 = this.applicationConfigService.getMicroservicePrefix();

    if (!request.url || (request.url.startsWith('http') && !((serverApiUrl && request.url.startsWith(serverApiUrl)) || (serverApiUrl2 && request.url.startsWith(serverApiUrl2))) )) {
      return next.handle(request);
    }

    const token: string | null = localStorage.getItem('authenticationToken') ?? sessionStorage.getItem('authenticationToken');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }
}

