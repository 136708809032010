import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import { AccountService } from '../auth/account.service';

@Injectable()
export class OrderDetailsGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.accountService.identity().pipe(
      map((account) => {
        if (account) {
          if (this.accountService.hasAnyAuthority('ROLE_ADMIN')) {
            this.router.navigate([
              `/admin/usuarios/${route.params['clientId']}/encomendas/${route.params['orderId']}`,
            ]);
          } else {
            this.router.navigate([
              `/encomendas/${route.params['orderId']}/detalhes`,
            ]);
          }
        }
        return true;
      })
    );
  }
}
