import * as dayjs from 'dayjs';
import { IReceiver } from 'src/app/entities/receiver/receiver.model';
import { ISender } from 'src/app/entities/sender/sender.model';
import { IOrderInfo } from 'src/app/entities/order-info/order-info.model';
import { ITransporter } from 'src/app/entities/transporter/transporter.model';
import { ITag } from 'src/app/entities/tag/tag.model';
import { OrderStatus } from 'src/app/entities/enumerations/order-status.model';

export interface IOrder {
  id?: number;
  createdDate?: dayjs.Dayjs | null;
  status?: OrderStatus | null;
  receiver?: IReceiver | null;
  sender?: ISender | null;
  infos?: IOrderInfo | null;
  transporter?: ITransporter | null;
  tags?: ITag[] | null;
}

export class Order implements IOrder {
  constructor(
    public id?: number,
    public createdDate?: dayjs.Dayjs | null,
    public status?: OrderStatus | null,
    public receiver?: IReceiver | null,
    public sender?: ISender | null,
    public infos?: IOrderInfo | null,
    public transporter?: ITransporter | null,
    public tags?: ITag[] | null
  ) { }
}

export function getOrderIdentifier(order: IOrder): number | undefined {
  return order.id;
}
