import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { log } from 'console';
import { AccountService } from 'src/app/core/auth/account.service';

@Component({
  selector: 'app-client-nav-links',
  templateUrl: './client-nav-links.component.html',
  styleUrls: ['./client-nav-links.component.scss'],
})
export class ClientNavLinksComponent implements OnInit {
  @Input('route') route: string = '/encomendas';
  @Output('onNavLinkClicked') onNavLinkClicked = new EventEmitter<string>();

  public isPartner: ConstrainBoolean = false;

  constructor(
    private router: ActivatedRoute,
    private readonly accountService: AccountService
  ) {
    this.isPartner = this.accountService.isPartner();
  }

  ngOnInit(): void {
    this.route = '/encomendas';
  }

  navigate(path: string) {
    this.onNavLinkClicked.emit(path);
  }
}
