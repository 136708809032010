import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class WindowRefService {

  private window: Window;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView!;
  }

  check() {
    console.log(this.document);
    console.log(this.window);
  }

  getWindow() {
    return this.window;
  }
}
