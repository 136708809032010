<div class="tag-template" *ngIf="order && order.trackingCode">
  <div class="dyzon">
    <div class="logo-dyzon">
      <img
        height="25"
        src="assets\\images\\svg\\dyzon-logo-svg-small.svg"
        alt="dyzon"
      />
    </div>
    <div class="info">
      <p>
        Pedido: <b>{{ order!.number }}</b>
      </p>
      <p>
        ShipmentId: <b>{{ order!.shipmentId }}</b>
      </p>
      <p>
        Cód. Rastreio: <b>{{ order!.trackingCode }}</b>
      </p>
    </div>
    <div class="logo-jadlog">
      <img
        height="20"
        width="60px"
        src="assets\\images\\transporters\\jadlog.png"
        alt="dyzon"
      />
      <p style="font-size: 10px">.Package</p>
    </div>
  </div>

  <div class="top">
    <div class="container">
      <div class="transporter-head-column">
        <p>JADLOG - DPD GROUP</p>
      </div>
      <div class="f-l">
        <div class="destiny-location-column">
          <div>
            <p>
              {{ order!.detail!.receiver.address.line_1 }}
              {{ order!.detail!.receiver.address.number }}
              {{ order!.detail!.receiver.address.neighborhood }}
            </p>
            <p>
              {{ order!.detail!.receiver.address.postal_code }}
              {{ order!.detail!.receiver.address.city }} -
              {{ order!.detail!.receiver.address.state }}
            </p>
            <p>{{ order!.detail!.receiver.address.line_2 }}</p>
          </div>
          <div class="flex">
            <p class="m-0">RECEBEDOR:</p>
            <span class="signature w-100"></span>
          </div>
        </div>
        <div class="destiny-contact-column">
          <p>Contact: {{ order!.detail!.receiver.name }}</p>
          <p>Phone: {{ order!.detail!.receiver.phone_number }}</p>
          <p>Info</p>
        </div>
        <div class="package-info-column">
          <p>packages</p>
          <p>001/001</p>
          <p>weight</p>
          <p>{{ order!.detail!.pack.weight }}</p>
        </div>
        <div class="destiny-unit-column">
          <p>{{ order!.detail!.sender.name }}</p>
          <p>
            {{ order!.detail!.sender.address.line_1 }},
            {{ order!.detail!.sender.address.number }}
          </p>
          <p>{{ order!.detail!.sender.address.neighborhood }}</p>
          <p>
            {{ order!.detail!.sender.address.postal_code }}
            {{ order!.detail!.sender.address.city }}
            {{ order!.detail!.sender.address.state }}
          </p>
          <p>fone {{ order!.detail!.sender.phone_number }}</p>
        </div>
        <div class="priority-column">
          <p *ngIf="order!.label.etiqueta" [class.hidden]="isTest">
            {{ order!.label.etiqueta.volume[0].prioridade }}
          </p>
        </div>
      </div>
      <div class="f-r">
        <div class="warehouse">
          <p *ngIf="order!.label.etiqueta" [class.hidden]="isTest">
            {{ order!.label.etiqueta.volume[0].rua }}
          </p>
          <p *ngIf="order!.label.etiqueta" [class.hidden]="isTest">
            {{ order!.label.etiqueta.volume[0].posicao }}
          </p>
        </div>
        <div
          class="origin"
          style="transform: rotate(-270deg); -webkit-transform: rotate(-270deg)"
        >
          <p *ngIf="order!.label.etiqueta">
            {{ order!.label.etiqueta.volume[0].unidadeDestino }}
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="barcode">
        <div style="transform: scale(1.3)">
          <div
            style="
              transform: rotate(-270deg);
              -webkit-transform: rotate(-270deg);
            "
          >
            <ngx-barcode
              [bc-value]="order!.shipmentId! + '$00' + getSequential()"
              [bc-height]="30"
              [bc-width]="1.0"
              bc-element-type="canvas"
              class="p-0"
              [bc-margin-top]="0"
              [bc-margin-bottom]="0"
              [bc-margin-left]="0"
              [bc-margin-right]="0"
              [bc-display-value]="false"
              [bc-font-size]="30"
              [bc-text-margin]="-1"
            >
            </ngx-barcode>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="order!.label.etiqueta" class="bottom">
    <div class="container">
      <div class="ship-id">
        <p>{{ shipmentId() }}</p>
      </div>
      <div class="ship-type">
        <!-- <p>std</p> -->
        <p></p>
      </div>
      <div class="ship-nb">
        <p></p>
      </div>
    </div>
    <div class="container flex justify-between">
      <div class="priority">
        <p>Track</p>
        <p>{{ order!.label.etiqueta.volume[0].prioridade }}</p>
        <p class="m-0">00002</p>
      </div>
      <div class="route">
        <p>{{ order!.label.etiqueta.volume[0].rota }}</p>
      </div>
      <div class="route-last-mile">
        <p>{{ order!.label.etiqueta.volume[0].lastMile }}</p>
      </div>
    </div>
    <div class="container">
      <div class="shippment-id">
        <ngx-barcode
          class="p-0"
          [bc-height]="70"
          [bc-width]="1.6"
          [bc-margin-top]="0"
          [bc-margin-bottom]="0"
          [bc-margin-left]="0"
          [bc-margin-right]="0"
          bc-element-type="canvas"
          [bc-display-value]="false"
          [bc-font-size]="30"
          [bc-text-margin]="-1"
        >
        </ngx-barcode>
      </div>
    </div>
  </div>
</div>
