<ul nz-menu nzTheme="light" nzMode="horizontal" class="header-menu">
  <li nz-menu-item [nzSelected]="route === '' || route === '/encomendas'">
    <a [routerLink]="['/encomendas']">Meus Envios</a>
  </li>
  <!-- <li nz-menu-item [nzSelected]="route.includes('cotacoes')">
    <a [routerLink]="[ '/cotacoes' ]">Cotações Salvas</a>
  </li> -->
  <li class="disable" nz-menu-item [nzSelected]="route.includes('calculadora')">
    <a [routerLink]="['/calculadora']">Calculadora De Fretes</a>
  </li>
  <li
    nz-menu-item
    [hidden]="isPartner"
    [nzSelected]="route.includes('conferencia')"
  >
    <a [routerLink]="['/conferencia']">Conferência</a>
  </li>

  <li
    nz-menu-item
    [hidden]="isPartner"
    [nzSelected]="route.includes('whatsapp')"
  >
    <a [routerLink]="['/whatsapp']">Whatsapp</a>
  </li>
  <li class="disable" nz-menu-item>
    <a [routerLink]="['/encomendas']" [queryParams]="{ currentTab: 3 }"
      >Pagamento Pendente</a
    >
  </li>
</ul>
