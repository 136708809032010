import { HttpParams } from '@angular/common/http';

export type Params = {
  eq?: string | string[];
  neq?: string | string[];
  gt?: string | string[];
  gte?: string | string[];
  lt?: string | string[];
  lte?: string | string[];
  isnull?: string | string[];
  notnull?: string | string[];
  type?: string | string[];
  desc?: string;
  asc?: string;
  offset?: number;
  page?: number;
};

export const createRequestOption = (req?: Params | any): HttpParams => {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach(key => {
      if (typeof key === 'object') {
        (req[key] as Array<string>).forEach(subKey => {
          options.append(key, subKey)
        });
      } else {
        options = options.set(key, req[key]);
      }
    });


  }

  return options;
};
