import { AssetsModel } from './assets.model';

export class CorreiosLogo extends AssetsModel {
  public get asset(): string {
    return 'assets\\images\\transporters\\correios.png';
  }

  public png() {
    return 'assets\\images\\transporters\\correios.png';
  }

  public svg() {
    return 'assets\\images\\transporters\\correios.svg';
  }
}
