import { TagHolderComponent } from './shared/components/tag-holder/tag-holder.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/auth/user-route-access.service';
import { OrderDetailsGuard } from './core/guards/order-details.guard';
import { QuotationShareResolver } from './pages/client/quotation-share/quotation-share.resolver';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'cotacaocompartilhada',
    loadChildren: () =>
      import('./pages/client/quotation-share/quotation-share.module').then(
        (m) => m.QuotationShareModule
      ),
    resolve: { data: QuotationShareResolver },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'usuarios/:clientId/encomendas/:orderId',
    component: AppComponent,
    canActivate: [OrderDetailsGuard],
  },
  {
    path: 'calculadora',
    loadChildren: () =>
      import('./pages/calculator/calculator.module').then(
        (m) => m.CalculatorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'carrinho',
    loadChildren: () =>
      import('./pages/cart/cart.module').then((m) => m.CartModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./pages/account-profile/account-profile.module').then(
        (m) => m.AccountProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pagamentos',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'rastreio',
    loadChildren: () =>
      import('./pages/tracking/tracking.module').then((m) => m.TrackingModule),
  },
  {
    path: 'print',
    component: TagHolderComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
