import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../components/navbar-component/navbar.component';
import { AdminNavLinksComponent } from '../../components/navbar-component/admin-nav-links/admin-nav-links.component';
import { SharedComponentsModule } from '../shared-components.module';
import { ProfileCardComponent } from '../../components/profile-card/profile-card.component';
import { ClientNavLinksComponent } from '../../components/navbar-component/client-nav-links/client-nav-links.component';
import { FormsModule } from '@angular/forms';
import { NotificationCardComponent } from '../../components/notification-card/notification-card.component';
import { CustomPipeModule } from '../custom-pipes.module';

@NgModule({
  declarations: [
    NavbarComponent,
    ClientNavLinksComponent,
    AdminNavLinksComponent,
    ProfileCardComponent,
    NotificationCardComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    CustomPipeModule
  ],
  exports: [
    NavbarComponent,
    AdminNavLinksComponent,
    ClientNavLinksComponent
  ],
})
export class NavbarModule { }
