<div *ngIf="show === true">
  <div class="wrapper">
    <nz-card>

      <nz-divider nzPlain></nz-divider>

      <div class="arrow-up"></div>



    </nz-card>
  </div>

  <div class="background" (click)="close($event)"></div>

</div>
