import { AssetsModel } from './assets.model';

export class AzulExpressLogo extends AssetsModel {
  public get asset(): string {
    return 'assets\\images\\transporters\\express.png';
  }

  public png() {
    return 'assets\\images\\transporters\\express.png';
  }

  public svg() {
    return 'assets\\images\\transporters\\express.svg';
  }
}
