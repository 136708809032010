<div nz-row [nzGutter]="{ xs: 8, sm: 12, md: 12, lg: 12 }" class="mh-20 flex justify-center">
  <div nz-col [nzSpan]="6" [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="18" [nzXl]="24" [nzXXl]="12"
    class="social-buttons">
    <button nz-button nzType="default" nzSize="large" class="btn-extra w-100"><span><img class="mr-10"
          src="assets\icons\svg\google-icon.svg" alt="google-icon"></span>
      Entrar com Google</button>
  </div>
  <div nz-col [nzSpan]="6" [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="18" [nzXl]="24" [nzXXl]="12"
    class="social-buttons">
    <button nz-button nzType="default" nzSize="large" class="btn-extra w-100"
      style="background-color: #176EF0; color:#FFF">
      <span><img src="assets\icons\svg\fb-icon.svg" alt="fb-icon" class="mr-10"></span> Entrar com
      Facebook</button>
  </div>
</div>
