import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationConfigService } from '../config/application-config.service';
import { Login } from 'src/app/entities/login/login.model';

import jwtDecode from 'jwt-decode';
import { EventManager } from '../util/event-manager.service';
import { EventType } from 'src/app/shared/services/event-type.constants';

type JwtToken = {
  id_token: string;
};

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  constructor(
    private http: HttpClient,
    private applicationConfigService: ApplicationConfigService,
    private eventManager: EventManager
  ) {}

  private decodePayloadJWT(): any {
    try {
      return jwtDecode(this.getToken());
    } catch (Error) {
      return null;
    }
  }

  getTokenDecoded() {
    return this.decodePayloadJWT();
  }

  getToken(): string {
    const tokenInLocalStorage: string | null = localStorage.getItem(
      'authenticationToken'
    );
    const tokenInSessionStorage: string | null = sessionStorage.getItem(
      'authenticationToken'
    );
    return tokenInLocalStorage ?? tokenInSessionStorage ?? '';
  }

  login(credentials: Login): Observable<void> {
    return this.http
      .post<JwtToken>(
        this.applicationConfigService.getEndpointFor('login', 'auth'),
        credentials
      )
      .pipe(
        map((response) =>
          this.authenticateSuccess(response, credentials.rememberMe)
        )
      );
  }

  logout(): Observable<void> {
    return new Observable((observer) => {
      localStorage.removeItem('authenticationToken');
      sessionStorage.removeItem('authenticationToken');
      localStorage.clear();
      sessionStorage.clear();
      observer.complete();
    });
  }

  private authenticateSuccess(response: JwtToken, rememberMe: boolean): void {
    const jwt = response.id_token;
    
    if (rememberMe) {
      localStorage.setItem('authenticationToken', jwt);
      sessionStorage.removeItem('authenticationToken');
    } else {
      sessionStorage.setItem('authenticationToken', jwt);
      localStorage.removeItem('authenticationToken');
    }

    this.eventManager.broadcast(EventType.authentication.login);
  }
}
