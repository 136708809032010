import { AssetsModel } from '../assets-model/assets.model';
import { AzulExpressLogo } from '../assets-model/azulexpress.assets.model';
import { CorreiosLogo } from '../assets-model/correios.assets.model';
import { DhlLogo } from '../assets-model/dhl.assets.model';
import { JadlogLogo } from '../assets-model/jadlog.assets.model';

export class TransporterLogoFactory {
  public create(type: string): AssetsModel | null {
    switch (type) {
      case 'correios':
        return new CorreiosLogo();
      case 'jadlog':
        return new JadlogLogo();
      case 'dhl':
        return new DhlLogo();
      case 'azulexpress':
        return new AzulExpressLogo();
      default:
        return null;
    }
  }
}
