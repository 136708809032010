import { BoobleModalService } from 'booble-modal';
import { GetOrderDto } from './../../../dtos/get-order.dto';
import {
  Component,
  AfterViewInit,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-tag-holder',
  templateUrl: './tag-holder.component.html',
  styleUrls: ['./tag-holder.component.scss'],
})
export class TagHolderComponent implements OnInit, AfterViewInit {
  boobleModalData!: { orders: GetOrderDto[] };
  items?: Array<GetOrderDto[]>;
  loading = true;
  claim = false;

  showTag = true;
  showClaim = false;

  @ViewChild('tagHolder') tagHolder!: ElementRef<HTMLDivElement>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly modalService: BoobleModalService
  ) {
    const data = localStorage.getItem('print-data');
    if (data) {
      this.boobleModalData = JSON.parse(data);
    }
  }

  ngOnInit(): void {
    this.items = [];
    if (this.boobleModalData.orders.length >= 4) {
      for (
        let index = 0;
        index < this.boobleModalData.orders.length;
        index += 4
      ) {
        this.items.push(this.boobleModalData.orders.slice(index, index + 4));
      }
    } else {
      this.items.push(this.boobleModalData.orders);
    }
  }

  createPdf(): void {
    const date = new Date();
    const options = {
      margin: 1,
      filename: `etiquetas-${date.toISOString()}.pdf`,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
    };

    try {
      html2pdf()
        .from(this.tagHolder.nativeElement)
        .set(options)
        .toPdf()
        .get('pdf')
        .outputPdf('blob')
        .then((pdfBlob) => {
          const blobUrl = URL.createObjectURL(pdfBlob);
          console.log('Blob URL criado:', blobUrl);

          const isMobile = /iPhone|iPad|iPod|Android/i.test(
            navigator.userAgent
          );

          if (isMobile) {
            window.location.href = blobUrl;
          } else {
            document.body.innerHTML = '';
            const iframe = document.createElement('iframe');
            iframe.id = 'pdf-iframe';
            iframe.src = blobUrl;
            iframe.style.width = '100%';
            iframe.style.height = '100vh';
            iframe.style.border = 'none';
            document.body.appendChild(iframe);
            console.log('Iframe criado e adicionado ao DOM:', iframe);
          }

          // Liberar o blob após o uso
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error('Erro ao gerar o PDF:', error);
        });
    } catch (error) {
      console.error('Erro ao tentar criar o PDF:', error);
    }
  }

  ngAfterViewInit(): void {
    // Delay para garantir que o conteúdo do DOM esteja totalmente carregado
    setTimeout(() => {
      this.print();
    }, 1000); // ajuste o tempo conforme necessário
  }

  print(): void {
    this.createPdf();
  }
}
