import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventManager } from 'src/app/core/util/event-manager.service';
import { EventType } from '../../services/event-type.constants';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

  show: any;

  constructor(
    private router: Router,
    private eventManager: EventManager
  ) { }

  ngOnInit(): void {
    this.eventManager.subscribe(
      EventType.template.notification.card.open,
      () => (this.show = true)
    );
    this.show = false;
  }


  close(event: any) {
    event.stopPropagation();
    this.show = false;
  }


}
