import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'shortWeekDay' })
export class ShortWeekDayDatePipe extends DatePipe implements PipeTransform {
  override transform(value: any, args?: any): any {
    const weekday = super.transform(value, 'EEEE');
    return weekday?.split('-')[0];
  }
}
