<div class="grid" id="tagHolder" #tagHolder>
  <ng-container *ngFor="let item of items">
    <div class="row tag-holder">
      <div
        *ngFor="let order of item"
        [ngSwitch]="order.detail.transporter.code"
        class="tag"
      >
        <ng-container *ngSwitchCase="'jadlog'">
          <app-tag-template
            class="mr-5 mt-5"
            [order]="order"
          ></app-tag-template>
        </ng-container>
        <ng-container *ngSwitchCase="'correios'">
          <app-tag-correios
            class="mr-5 mt-5"
            [order]="order"
          ></app-tag-correios>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngFor="let item of items">
    <div
      *ngFor="let order of item"
      [ngSwitch]="order.detail.transporter.code"
      class="row claim"
    >
      <ng-container *ngSwitchCase="'jadlog'">
        <app-jadlog-content [order]="order"></app-jadlog-content>
      </ng-container>
      <ng-container *ngSwitchCase="'correios'">
        <app-correios-content [order]="order"></app-correios-content>
      </ng-container>
    </div>
  </ng-container>
</div>

<div *ngIf="loading" class="loader">
  <span nz-icon nzType="loading"></span>
</div>
