import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from 'src/app/core/auth/account.service';

@Injectable()
export class NavigationService {
  private navigationStartSubject: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );
  private readonly isAdmin: boolean;

  constructor(
    private readonly router: Router,
    private readonly accountService: AccountService
  ) {
    this.isAdmin = this.accountService.isAdmin();
  }

  get previousRoute() {
    return this.navigationStartSubject.value;
  }

  set previousRoute(value: string) {
    this.navigationStartSubject.next(value);
  }

  navigate(route: Routes) {
    let url = route.toString();

    if (this.isAdmin) {
      url = '/admin' + url;
    }

    this.router.navigate([url]);
  }

  navigateByUrl(url: string) {
    if (this.isAdmin) {
      url = '/admin/' + url;
    }
    this.router.navigate([url], {
      onSameUrlNavigation: 'reload',
      skipLocationChange: true,
    });
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}

export enum Routes {
  ORDERS = '/encomendas',
  QUOTATIONS = '/cotacoes',
  TRANSPORTERS = '/transportadoras',
}
