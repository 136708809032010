import { PubsubService } from 'src/app/shared/services/pubsub.service';
import { BoobleModalService } from 'booble-modal';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServerProvider } from './core/auth/auth-jwt.service';
import { EventManager } from './core/util/event-manager.service';
import { GetOrderDto } from './dtos/get-order.dto';
import { EventType } from './shared/services/event-type.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    eventManager: EventManager,
    authService: AuthServerProvider,
    pubSubService: PubsubService,
    private readonly router: Router
  ) {
    eventManager.subscribe(EventType.httpError.unauthorized, () => {
      if (!router.url.includes('/login'))
        eventManager.broadcast(EventType.authentication.logout);
    });

    eventManager.subscribe(EventType.authentication.logout, () => {
      authService.logout().subscribe();
      router.navigate(['login']);
    });

    pubSubService.register(EventType.tag.print)?.subscribe({
      next: (orders) => {
        if (orders) {
          this.print(orders as GetOrderDto[]);
        }
      },
    });
  }

  print(orders: GetOrderDto[], claim = false) {
    localStorage.setItem('print-data', JSON.stringify({ orders }));

    const uri = claim ? `/print?withClaim=true` : `/print`;
    const url = this.router.serializeUrl(this.router.createUrlTree([uri]));

    window.open(url, '_blank');
  }

}
