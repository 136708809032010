import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'shortCurrency' })
export class ShortCurrencyPipe extends CurrencyPipe implements PipeTransform {
  override transform(value: any, args?: any): any {
    const currency = super.transform(value);
    return currency?.split('R$')[1].trim();
  }
}
