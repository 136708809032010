import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'src/app/shared/modules/ng-ant-design.module';
import { LogoComponent } from '../components/logo-component/logo/logo.component';
import { SocialButtonsComponent } from '../components/social-buttons/social-buttons.component';
import { TagModule } from './tag/tag.module';

@NgModule({
  declarations: [
    LogoComponent,
    SocialButtonsComponent,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TagModule
  ],
  exports: [
    NgZorroAntdModule,
    TagModule,
    LogoComponent,
    SocialButtonsComponent,
  ]
})
export class SharedComponentsModule { }
