<ul nz-menu nzTheme="light" nzMode="horizontal" class="header-menu">
  <li
    nz-menu-item
    [nzSelected]="route === '/admin' || route === '/admin/dashboard'"
    (click)="navigate('dashboard')"
  >
    <a [routerLink]="['/admin', 'dashboard']">Dashboard</a>
  </li>
  <li nz-menu-item [nzSelected]="route.includes('/admin/encomendas')">
    <a [routerLink]="['/admin', 'encomendas']">Envios</a>
  </li>
  <!-- <li nz-menu-item [nzSelected]="route.includes('/admin/cotacoes')">
    <a [routerLink]="['/admin', 'cotacoes']">Cotações Salvas</a>
  </li> -->
  <li
    nz-menu-item
    [nzSelected]="route.includes('/admin/conferencias')"
    (click)="navigate('conferencias')"
  >
    <a [routerLink]="['/admin', 'conferencias']">Conferência</a>
  </li>
  <li
    nz-menu-item
    [nzSelected]="route.includes('/admin/usuarios')"
    (click)="navigate('usuarios')"
  >
    <a [routerLink]="['/admin', 'usuarios']">Usuários</a>
  </li>
  <li
    nz-menu-item
    [nzSelected]="route.includes('/admin/transportadoras')"
    (click)="navigate('transportadoras')"
  >
    <a [routerLink]="['/admin', 'transportadoras']">Transportadoras</a>
  </li>
  <li
    nz-menu-item
    [nzSelected]="route.includes('/admin/financeiro')"
    (click)="navigate('financeiro')"
  >
    <a [routerLink]="['/admin', 'financeiro']">Financeiro</a>
  </li>
  <li
    nz-menu-item
    [nzSelected]="route.includes('/admin/whatsapp')"
    (click)="navigate('whatsapp')"
  >
    <a [routerLink]="['/admin', 'whatsapp']">Whatsapp</a>
  </li>
</ul>
