import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type PubSubEvent = { type: string; value: BehaviorSubject<any> };

@Injectable({
  providedIn: 'root',
})
export class PubsubService {
  private registeredEvents: PubSubEvent[] = [];

  private retrieveEvent(type: any): BehaviorSubject<any> {
    return this.registeredEvents.find((e) => e.type === type)
      ?.value as BehaviorSubject<any>;
  }

  constructor() { }

  public publish(type: any, data?: any) {
    this.retrieveEvent(type).next(data);
  }

  public register<T>(type: any): Observable<T> | null {
    if (!this.registeredEvents.some((e: PubSubEvent) => e.type === type)) {
      this.registeredEvents.push({ type, value: new BehaviorSubject(null) });
    }

    return this.retrieveEvent(type)?.asObservable();
  }
}
