import { GetOrderDto } from './../../../dtos/get-order.dto';
import { OrderService } from './../../../entities/order/service/order.service';
import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GetQuotationDto } from 'src/app/dtos/get-quotation.dto';

@Injectable({ providedIn: 'root' })
export class QuotationShareResolver implements Resolve<GetOrderDto> {
  constructor(private orderService: OrderService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GetOrderDto> {
    const code = route.queryParamMap.get('codigo');
    console.log('codigo', code)

    return this.orderService.shared(code!);
  }
}
