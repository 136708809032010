import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { httpInterceptorProviders } from 'src/app/core/interceptor/index';
import pt from '@angular/common/locales/pt';
import { NavbarModule } from './shared/modules/navbar/navbar.module';
import { AuthGuard } from './core/auth/user-route-access.service';
import { ShortWeekDayDatePipe } from './shared/pipes/custom-date.pipe';
import { OrderDetailsGuard } from './core/guards/order-details.guard';
import { NavigationModule } from './shared/modules/navigation.module';
import { WindowRefService } from './shared/services/window-ref.service';
registerLocaleData(pt);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NavbarModule,
    NavigationModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: NZ_I18N, useValue: pt_BR },
    { provide: NZ_ICONS, useValue: icons },
    WindowRefService,
    httpInterceptorProviders,
    AuthGuard,
    OrderDetailsGuard,
    ShortWeekDayDatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
