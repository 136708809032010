import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, firstValueFrom } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';

import { StateStorageService } from 'src/app/core/auth/state-storage.service';
import { ApplicationConfigService } from '../config/application-config.service';
import { Account } from 'src/app/core/auth/account.model';
import { AuthServerProvider } from './auth-jwt.service';
import { EventManager } from '../../core/util/event-manager.service';
import { EventType } from 'src/app/shared/services/event-type.constants';
import { IAddress } from 'src/app/entities/address/address.model';
import { IUser } from 'src/app/entities/user/user.model';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;

  constructor(
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private router: Router,
    private applicationConfigService: ApplicationConfigService,
    private authServerProvider: AuthServerProvider,
    private eventManager: EventManager
  ) {}

  save(account: Account): Observable<{}> {
    return this.http.post(
      this.applicationConfigService.getEndpointFor('account'),
      account
    );
  }

  activate(codigo: string): Observable<Account> {
    return this.http.patch<Account>(
      `${this.applicationConfigService.getEndpointFor(
        'account'
      )}/activate?key=${codigo}`,
      null
    );
  }

  isAdmin(): boolean {
    const auth = this.authServerProvider.getTokenDecoded();
    if (!auth) return false;
    return auth.roles?.some((role: any) => role.name === 'ROLE_ADMIN') ?? false;
  }

  isPartner(): boolean {
    const auth = this.authServerProvider.getTokenDecoded();
    console.log(auth);
    if (!auth) return false;
    return auth.isPartner;
  }

  update(user: IUser | Account) {
    return this.http.put<IUser>(
      `${this.applicationConfigService.getEndpointFor('account')}`,
      user
    );
  }

  addAddress(address: IAddress) {
    return this.http.put(
      `${this.applicationConfigService.getEndpointFor('account')}/addresses`,
      address
    );
  }

  async validate(password: string) {
    const identity = await firstValueFrom(this.identity());
    if (!identity) this.eventManager.broadcast(EventType.authentication.logout);

    const credentials = {
      username: identity!.email,
      password,
    };
    return this.http.post(
      this.applicationConfigService.getEndpointFor('login', 'auth'),
      credentials
    );
  }

  authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return (
      this.authServerProvider
        .getTokenDecoded()
        .roles?.some((authority: any) =>
          authorities.includes(authority.name)
        ) ?? false
    );
  }

  getWallet() {
    return this.http.get<{ balance: number }>(
      `${this.applicationConfigService.getMicroservicePrefix()}wallet/balance`
    );
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => of(null)),
        tap((account: Account | null) => {
          this.authenticate(account);

          if (account) {
            this.navigateToStoredUrl();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.userIdentity?.imageUrl ?? '';
  }

  private fetch(): Observable<Account> {
    return this.http.get<Account>(
      this.applicationConfigService.getEndpointFor('account', 'auth')
    );
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }
}
