<div class="content">
  <div class="grid-2-header">
    <div>
      <div class="cell-single text-center">
        <p class="m-0"><b>DECLARAÇÃO DE CONTEÚDO</b></p>
        <p class="m-0"><b>{{ order.trackingCode }}</b></p>
      </div>

      <div class="divider"></div>

      <div class="cell-top">
        <p class="text-center" style="margin: 0;">CAMPOS EXCLUSIVOS DOS CORREIOS</p>
      </div>

      <div class="grid-2-correios">
        <div class="cell-bottom">
          <small>UNIDADE DE POSTAGEM</small>
          <p>&nbsp;</p>
        </div>

        <div class="cell-bottom">
          <small>NÚMERO DO REGISTRO</small>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>

    <div class="cell-single text-center stamp">
      <small class="stamp-span">CARIMBO DA UNIDADE</small>
    </div>
  </div>


  <div class="divider"></div>

  <table>
    <tr>
      <td>
        <b>REMETENTE:</b> {{ order.detail.sender.name }}
      </td>
    </tr>

    <tr>
      <td>
        <b>CPF/CNPJ:</b> {{ order.detail.sender.document }}
      </td>
    </tr>

    <tr>
      <td>
        <b>ENDEREÇO:</b>
        {{ order.detail.sender.address.line1 }}, {{ order.detail.sender.address.number }} {{
        order.detail.sender.address.line2 }} Bairro {{ order.detail.sender.address.neighborhood }}
      </td>
    </tr>

    <tr>
      <td style="padding: 0 3px; height: 25px;">
        <div class="double-data">
          <span><b>CIDADE/UF:</b>
            {{ order.detail.sender.address.city }} - {{ order.detail.sender.address.state }}</span>
          <span class="last-data"><b>CEP:</b> {{ order.detail.sender.address.postalCode | mask: masks.CEP_MASK }}
          </span>
        </div>
      </td>

    </tr>
  </table>

  <div class="divider"></div>

  <table>
    <tr>
      <td>
        <b>DESTINATÁRIO:</b> {{ order.detail.receiver.name }}
      </td>
    </tr>

    <tr>
      <td>
        <b>CPF/CNPJ:</b> {{ order.detail.receiver.document }}
      </td>
    </tr>

    <tr>
      <td>
        <b>ENDEREÇO:</b> {{ order.detail.receiver.address.line1 }}, {{ order.detail.receiver.address.number }} {{
        order.detail.receiver.address.line2 }} Bairro {{ order.detail.receiver.address.neighborhood }}
      </td>
    </tr>

    <tr>
      <td style="padding: 0 3px; height: 25px;">
        <div class="double-data">
          <span><b>CIDADE/UF:</b>
            {{ order.detail.receiver.address.city }} - {{ order.detail.receiver.address.state }}</span>
          <span class="last-data"><b>CEP:</b>
            {{ order.detail.receiver.address.postalCode | mask: masks.CEP_MASK }}</span>
        </div>
      </td>

    </tr>
  </table>

  <div class="divider"></div>

  <table>
    <tr span>
      <th colspan="3">
        IDENTIFICAÇÃO DOS BENS
      </th>
    </tr>

    <tr>
      <th>DISCRIMINAÇÃO DO CONTEÚDO</th>
      <th>QUANTIDADE</th>
      <th>VALOR</th>
    </tr>

    <tr>
      <td>{{item1?.name}}</td>
      <td>{{item1?.quantity}}</td>
      <td>{{item1?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item2?.name}}</td>
      <td>{{item2?.quantity}}</td>
      <td>{{item2?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item3?.name}}</td>
      <td>{{item3?.quantity}}</td>
      <td>{{item3?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item4?.name}}</td>
      <td>{{item4?.quantity}}</td>
      <td>{{item4?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item5?.name}}</td>
      <td>{{item5?.quantity}}</td>
      <td>{{item5?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item6?.name}}</td>
      <td>{{item6?.quantity}}</td>
      <td>{{item6?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item7?.name}}</td>
      <td>{{item7?.quantity}}</td>
      <td>{{item7?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item8?.name}}</td>
      <td>{{item8?.quantity}}</td>
      <td>{{item8?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item9?.name}}</td>
      <td>{{item9?.quantity}}</td>
      <td>{{item9?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item10?.name}}</td>
      <td>{{item10?.quantity}}</td>
      <td>{{item10?.price | currency}}</td>
    </tr>

    <tr>
      <td>TOTAIS</td>
      <td> <span *ngIf="getItemsSum() > 0">{{ getItemsSum() | currency }}</span></td>
      <td></td>
    </tr>

    <tr>
      <td class="text-end">PESO TOTAL (kg)</td>
      <td colspan="2">{{ order.detail.pack.weight }}</td>
    </tr>
  </table>

  <div class="divider"></div>

  <table>
    <tr>
      <th>
        <b>DECLARAÇÃO</b>
      </th>
    </tr>

    <tr>
      <td>
        <div class="divider"></div>
        <p>
          Declaro que não me enquadro no conceito de contribuinte previsto no art. 4º da Lei
          Complementar nº 87/1996,
          uma vez que nãorealizo, com habitualidade ou em volume que caracterize intuito comercial, operações
          de
          circulação de mercadoria, ainda que se iniciemno exterior, ou estou dispensado da emissão da nota
          fiscal por
          força da legislação tributária vigente, responsabilizando-me, nos termos dalei e a quem de direito,
          por
          informações inverídicas.
        </p>

        <div class="divider"></div>
        <p>
          Declaro que estou dispensado da emissão de nota fiscal por força da legislação
          tributária vigente,
          responsabilizando-me, em todas ascircunstâncias legais, sobre qualquer informação inverídica
          declarada.
          Ainda, declaro que nesta embalagem não há qualquer conteúdoilícito, nocivo, tóxico, perigoso, nem a
          presença
          de animais vivos ou mortos, objetos com regulamentação específica de posse ou porte oucom
          comercialização ou
          uso proibido no Brasil.
        </p>

        <div class="divider"></div>
        <p>
          <b>
            Declaro estar plenamente ciente de que estou sujeito às penalidades legais
            civis e criminais em
            caso de
            declaração inverídica, incompleta e/ou incorreta relacionada ao conteúdo por mim informado neste
            documento.
          </b>
        </p>

        <div class="divider"></div>
        <p>
          Declaro ainda que não estou postando conteúdo inflamável, explosivo, causador de
          combustão
          espontânea,
          tóxico, corrosivo, gás ou qualquer outro conteúdo que conste na lista de proibições e restrições
          disponível
          no site dos Correios: <a
            href="http://www.correios.com.br/precisa-de-ajuda/proibicoes-e-restricoes/proibicoes-e-restricoes">http://www.correios.com.br/precisa-de-ajuda/proibicoes-e-restricoes/proibicoes-e-restricoes</a>
          contribuição social e qualquer acessório (Lei 8.137/90 Art. 1º, V).
        </p>

        <div class="divider"></div>

        <div style="display: flex; justify-content: space-between; padding: 0 10px;">
          <p>
            ________________ , _______ de ____________ de _______
          </p>

          <div style="display: flex; flex-direction: column;">
            <p>
              _________________________________
            <p class="text-center" style="margin: 0;">Assinatura do</p>
            <p class="text-center" style="margin-top: 0;">Declarante/Remetente</p>

          </div>
        </div>
      </td>
    </tr>
  </table>

  <div class="divider"></div>

  <div class="cell-single" style="padding: 3px 5px">
    <div>
      <span><b>OBSERVAÇÃO</b></span>
    </div>
    <p style="margin: 0; margin-bottom: 5px;">
      Constitui crime contra a ordem tributária suprimir ou reduzir tributo, ou contribuição social e qualquer
      acessório (Lei 8.137/90 Art. 1º, V).
    </p>
  </div>

</div>
