import { AssetsModel } from './assets.model';

export class JadlogLogo extends AssetsModel {
  public get asset(): string {
    return 'assets\\images\\transporters\\jadlog.png';
  }

  public png() {
    return 'assets\\images\\transporters\\jadlog.png';
  }

  public svg() {
    return 'assets\\images\\transporters\\jadlog.svg';
  }
}
