export const EventType = {
  tag: {
    print: 'print-tag'
  },
  notification: {
    new_notification: 'notification-new',
  },
  profile: {
    updated: 'profile-updated',
  },
  httpError: {
    unauthorized: 'app-unauthorized',
    invalid_credentials: 'app-invalid-credentials',
  },
  authentication: {
    login: 'app-login',
    logout: 'app-logout',
  },
  cart: {
    update_cart_itens: 'update-cart-itens'
  },
  wallet: {
    updated: 'wallet-update'
  },
  template: {
    notification: {
      card: {
        open: 'template-show-notification-card',
        close: 'template-hide-notification-card',
      }
    },
    profile: {
      card: {
        open: 'template-show-profile-card',
        close: 'template-hide-profile-card',
      },
    },
  },
  modal: {
    address: {
      address_created: 'address-created',
    },
  },
};
