import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationConfigService {
  private endpointPrefix = environment.API_URL;
  private endpointMicroservice = environment.API_URL_MICROSERVICE;
  private endpointDownloads = environment.API_URL_DOWNLOADS;

  setEndpointPrefix(endpointPrefix: string): void {
    this.endpointPrefix = endpointPrefix;
  }

  getEndpointPrefix() {
    return `${this.endpointPrefix}/v1/`;
  }

  getMicroserviceSeach(api: string) {
    return `${this.endpointMicroservice}/${api}/list`;
  }

  getMicroservicePrefix() {
    return `${this.endpointMicroservice}/`;
  }

  getEndpointDownloads() {
    return this.endpointDownloads;
  }

  getEndpointConf() {
    return `${this.endpointMicroservice}/conference/list/`;
  }

  getEndpointCot(api: string) {
    return `${this.endpointMicroservice}/${api}/list`;
  }

  getPayment(api: string) {
    return `${this.endpointMicroservice}/${api}`;
  }

  getTracking() {
    return `${this.endpointMicroservice}/tracking/list`;
  }

  getEndpointFor(api: string, microservice?: string): string {
    if (microservice) {
      return `${this.endpointMicroservice}/${microservice}/${api}`;
    }
    return `${this.endpointPrefix}/v1/${api}`;
  }
}
