import { PubsubService } from './../../services/pubsub.service';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { TransporterLogoFactory } from '../../models/factories/transporter-logo.factory';
import { GetOrderDto } from 'src/app/dtos/get-order.dto';

@Component({
  selector: 'app-tag-template',
  templateUrl: './tag-template.component.html',
  styleUrls: ['./tag-template.component.scss'],
})
export class TagTemplateComponent implements OnInit, AfterViewInit {
  logoFactory = new TransporterLogoFactory();
  boobleModalData?: GetOrderDto;
  @Input() order?: GetOrderDto;
  isTest = true;

  constructor(private readonly pubsubService: PubsubService) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    // this.pubsubService.register(EventType.tag.print)
    //   .subscribe({
    //     next: () => {
    //       // this.printBtn.nativeElement.click();
    //       window.print()
    //     }
    //   });
    if (this.boobleModalData) this.order = this.boobleModalData;
  }

  getSequential() {
    return this.order?.label?.etiqueta?.volume[0]?.seqVolume ?? 1;
  }

  shipmentId() {
    const shipmentId = this.order!.shipmentId!;
    const start = shipmentId.substring(0, 6);
    const middle = shipmentId.substring(6, 10);
    const end = shipmentId.substring(10, 14);
    return `${start} ${middle} ${end}`;
  }

  getBarcode() {
    const shipmentId = this.order!.shipmentId!;
    const destinationPostalCode =
      this.order!.detail.receiver.address.postalCode;
    return `${shipmentId}$0012${destinationPostalCode}`;
  }

  getWeight() {
    return `${this.order!.detail.pack.weight.toString().replace('.', ',')}`;
  }
}
