import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from 'src/app/core/auth/account.model';
import { EventManager } from 'src/app/core/util/event-manager.service';
import { EventType } from '../../services/event-type.constants';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  @Input('user') user: any;

  show: any;

  constructor(
    private router: Router,
    private eventManager: EventManager
  ) { }

  ngOnInit(): void {
    this.eventManager.subscribe(
      EventType.template.profile.card.open,
      () => (this.show = true)
    );
    this.show = false;
  }

  logout(event: any) {
    event.stopPropagation();
    this.eventManager.broadcast(EventType.authentication.logout);
    this.close(event);
  }

  editProfile(event: any) {
    event.stopPropagation();
    this.router.navigate(['perfil'])
    this.close(event);
  }

  cart(event: any) {
    event.stopPropagation();
    this.router.navigate(['carrinho']);
    this.close(event);
  }

  getTextUser(user: Account) {
    if (!user) return '';
    return user.firstName![0] + user.lastName![0];
  }

  close(event: any) {
    event.stopPropagation();
    this.show = false;
  }
}
