<div *ngIf="show === true">
  <div class="wrapper">
    <nz-card>
      <div class="flex justify-center align-center column">
        <div (click)="editProfile($event)" class="flex justify-center align-center mb-20">
          <nz-avatar *ngIf="user?.imageUrl" [nzSize]="80" nzIcon="user" [nzSrc]="user?.imageUrl ?? ''">
          </nz-avatar>
          <nz-avatar *ngIf="!user?.imageUrl" [nzSize]="80" style="background-color: #FF864A"
            [nzText]="getTextUser(user)"></nz-avatar>
        </div>
        <p class="paragraph-2 m-0">{{user.firstName}} {{user.lastName}}</p>
        <small class="m-0">{{user.email}}</small>
      </div>

      <nz-divider nzPlain></nz-divider>

      <div class="arrow-up"></div>
      <div (click)="editProfile($event)" class="flex align-center w-100 menu-button">
        <span nz-icon nzType="user" nzTheme="outline"></span>
        <p class="m-0">Perfil</p>
      </div>

      <div (click)="cart($event)" class="flex align-center w-100 menu-button">
        <span nz-icon nzType="wallet" nzTheme="outline"></span>
        <p class="m-0">Carteira</p>
      </div>

      <div (click)="cart($event)" class="flex align-center w-100 menu-button">
        <span nz-icon nzType="shopping-cart" nzTheme="outline"></span>
        <p class="m-0">Carrinho</p>
      </div>

      <div class="flex align-center w-100 menu-button" style="margin-bottom: 0;" (click)="logout($event)">
        <span nz-icon nzType="logout" nzTheme="outline"></span>
        <p class="m-0">Sair</p>
      </div>
    </nz-card>
  </div>

  <div class="background" (click)="close($event)"></div>

</div>
