<div class="content">

  <div class="flex">
    <p class="title" style="margin: auto;">
      <b>DECLARAÇÃO DE CONTEÚDO E TERMO DE RESPONSABILIDADE</b>
    </p>
  </div>
  <div class="divider"></div>
  <table>
    <tr>
      <td><b>Remetente</b>: {{ order.detail.sender.name }}</td>
      <td><b>CPF/CNPJ</b>: {{ order.detail.sender.document }}</td>

    </tr>

    <tr>
      <td>
        <p class="m-0"><b>Endereço:</b> {{ order.detail.sender.address.line1 }}, {{order.detail.sender.address.number}} {{
          order.detail.sender.address.line2 }} Bairro {{ order.detail.sender.address.neighborhood }}</p>
        <p class="m-0"><b>CEP:</b> {{ order.detail.sender.address.postalCode | mask: masks.CEP_MASK }}</p>
      </td>
      <td>
        <p class="m-0"><b>Cidade:</b> {{ order.detail.sender.address.city }}</p>
        <p class="m-0"><b>UF:</b> {{ order.detail.sender.address.state }}</p>
      </td>
    </tr>

    <tr>
      <th colspan="2">
        <p><b>DESTINATÁRIO</b></p>
      </th>
    </tr>

    <tr>
      <td colspan="2">
        <p class="m-0"><b>Nome:</b> {{ order.detail.receiver.name }}</p>
        <p class="m-0"><b>CPF/CNPJ:</b> {{ order.detail.receiver.document }}</p>
        <p class="m-0"><b>Endereço:</b> {{ order.detail.receiver.address.line1 }},
          {{ order.detail.receiver.address.number }} {{
          order.detail.receiver.address.line2 }} Bairro {{ order.detail.receiver.address.neighborhood }}</p>
        <div class="w-100 grid-3-data">
          <p class="m-0"><b>CEP:</b> {{ order.detail.receiver.address.postalCode | mask: masks.CEP_MASK }}</p>
          <p class="m-0"><b>Cidade:</b> {{ order.detail.receiver.address.city }}</p>
          <p class="m-0" style="margin-left: 40px"><b>UF:</b> {{ order.detail.receiver.address.state }}</p>

        </div>
      </td>
    </tr>
  </table>

  <table>
    <tr>
      <th colspan="3">
        <p><b>DESCRIÇÃO DE CONTEÚDO</b></p>
      </th>
    </tr>

    <tr>
      <th><b>Quantidade</b></th>
      <th><b>Descrição</b></th>
      <th><b>Valor (R$)</b></th>
    </tr>

    <tr>
      <td>{{item1?.name}}</td>
      <td>{{item1?.quantity}}</td>
      <td>{{item1?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item2?.name}}</td>
      <td>{{item2?.quantity}}</td>
      <td>{{item2?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item3?.name}}</td>
      <td>{{item3?.quantity}}</td>
      <td>{{item3?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item4?.name}}</td>
      <td>{{item4?.quantity}}</td>
      <td>{{item4?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item5?.name}}</td>
      <td>{{item5?.quantity}}</td>
      <td>{{item5?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item6?.name}}</td>
      <td>{{item6?.quantity}}</td>
      <td>{{item6?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item7?.name}}</td>
      <td>{{item7?.quantity}}</td>
      <td>{{item7?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item8?.name}}</td>
      <td>{{item8?.quantity}}</td>
      <td>{{item8?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item9?.name}}</td>
      <td>{{item9?.quantity}}</td>
      <td>{{item9?.price | currency}}</td>
    </tr>

    <tr>
      <td>{{item10?.name}}</td>
      <td>{{item10?.quantity}}</td>
      <td>{{item10?.price | currency}}</td>
    </tr>

  </table>

  <table>
      <tr>
        <th>
            <b>DAS RESPONSABILIDADES</b>
          </th>
      </tr>


    <tr>
      <td>

        Constitui crime contra a ordem tributária, suprimir ou reduzir tributos, ou contribuição social ou
        qualquer acessório; quando
        omitir informação ou prestar declaração falsa às autoridades fazendárias, deixar de fornecer,
        quando obrigatório, nota fiscal
        ou documento equivalente, relativo a venda de mercadoria ou prestação de serviços, efetivamente
        realizada, ou fornece-la
        em desacordo com a legislação. Pena - reclusão de 2 (dois) a 5 (cinco) anos, e multa
      </td>
    </tr>

    <tr>
      <th>
        DAS CONSIDERAÇÕES (LEI COMPLEMENTAR No 87/96 ART. 4o)
      </th>
    </tr>

    <tr>
      <td>

        É considerado contribuinte do ICMS qualquer pessoa, física ou jurídica, que realize, com
        habitualidade ou em volume que
        caracterize intuito comercial, operações de circulação de mercadoria ou prestações de serviços de
        transporte interestadual
        e intermunicipal e de comunicação, ainda que as operações e as prestações se iniciem no exterior.

      </td>
    </tr>

    <tr>
      <th>
        DECLARAÇÃO
      </th>
    </tr>

    <tr>
      <td>

        Declaro para os devidos fins de direito não ser pessoa jurídica ou pessoa física que realiza com
        frequência ou habitualidade, ou em
        volume que caracterize intuito comercial, operações de circulação de mercadoria, ainda que estas
        tenham se iniciado no exterior e que o
        conteúdo declarado não é composto por drogas, joias, carga perigosa, correspondências, dinheiro em
        espécie, materiais perecíveis, ou
        que configure crime previsto pelo código penal, crime ambiental previsto na lei 9.605 de 12/02/1998, bem
        como, a lei 7.679 de
        23/11/1998. e não estando sujeito à tributação, declaro ser o único responsável por eventuais
        penalidades ou danos decorrentes de
        informações inverídicas aqui prestadas, isentando de qualquer responsabilidade a JADLOG LOGISTICA S/A.
        Declaro que estou dispensado da emissão de nota fiscal por força da legislação tributária vigente,
        responsabilizando-me, em todas as
        circunstâncias legais, sobre qualquer informação inverídica declarada. Ainda, declaro que nesta
        embalagem não há qualquer conteúdo
        ilícito, nocivo, tóxico, perigoso, nem a presença de animais vivos ou mortos, objetos com regulamentação
        específica de posse ou porte ou
        com comercialização ou uso proibido no Brasil.
        Declaro estar plenamente ciente de que estou sujeito às penalidades legais civis e criminais em caso de
        declaração inverídica,
        incompleta e/ou incorreta relacionada ao conteúdo por mim informado neste documento.

        <div class="divider"></div>

        <p>
          LOCAL _____________________________ , ____ DE ______________ DE ________

        </p>

        <div class="divider"></div>

        <p> ________________________________________________</p>
        <p> ASSINATURA DO DECLARANTE/REMETENTE</p>
      </td>
    </tr>

    <tr>
      <th>
        <b>SENDO O DECLARANTE E/OU REMETENTE O ÚNICO RESPONSAVEL PELAS INFORMAÇÕES AQUI PRESTADAS</b>
      </th>
    </tr>
  </table>
</div>
