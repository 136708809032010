import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  EventManager,
  EventWithContent,
} from 'src/app/core/util/event-manager.service';
import { EventType } from 'src/app/shared/services/event-type.constants';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private eventManager: EventManager) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (
            !(
              err.status === 401 &&
              (err.message === '' || err.url?.includes('api/account'))
            )
          ) {
            if (err.status === 400) {
              if (err.message.includes('/authenticate')) {
                this.eventManager.broadcast(
                  EventType.httpError.invalid_credentials
                );
              } else {
              }
            }
            this.eventManager.broadcast(
              new EventWithContent('dyzonApp.httpError', err)
            );
          }
          // if (err.status === 401) {
          //   this.eventManager.broadcast(
          //     new EventWithContent(EventType.httpError.unauthorized, err)
          //   );
          // }
        },
      })
    );
  }
}
