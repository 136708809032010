<div class="tag-template" *ngIf="order && order.trackingCode">
  <div class="grid-3-column">
    <div class="cell flex align-start justify-start logo">
      <div>
        <img
          height="32"
          src="assets\\images\\svg\\dyzon-logo-svg-small.svg"
          alt="dyzon"
        />
      </div>
      <p></p>
    </div>

    <div class="cell flex align-center column">
      <div class="mt-10" [id]="'datamatrix-' + order.id"></div>
    </div>

    <div class="cell flex align-start justify-end pr-15">
      <div>
        <img height="90" width="90" [src]="getServiceLogo()" alt="dyzon" />
      </div>
    </div>
  </div>

  <div class="grid-3-column">
    <div class="cell flex column" style="padding-left: 15px">
      <p>NF: {{ order.label?.invoice }}</p>
      <p>Pedido: {{ order.id }}</p>
    </div>

    <div class="cell flex align-center column">
      <p class="text-center mt-10">
        <b>{{ order.label?.service }}</b>
      </p>
    </div>

    <div class="cell flex column pl-30">
      <p>Volume: 1/1</p>
      <p>
        Peso (g): <b>{{ order.detail.pack.weight * 1000 }}</b>
      </p>
    </div>
  </div>

  <div class="w-100 mt-10">
    <div class="flex column justify-start align-start ml-20">
      <div class="w-100 flex justify-between">
        <div class="flex justify-start" style="padding-left: 73px">
          <p style="margin-bottom: 0 !important; z-index: 1; font-size: 12px">
            <b>{{ order.trackingCode }}</b>
          </p>
        </div>
        <p
          style="
            margin-bottom: 0 !important;
            z-index: 1;
            font-size: 12px;
            margin-right: 10px;
          "
        >
          PLP: {{ order.shipmentId }}
        </p>
      </div>
      <ngx-barcode
        class="p-0"
        [bc-value]="getTrackingBarCode()"
        [bc-height]="70"
        [bc-width]="1.4"
        [bc-margin-top]="0"
        [bc-margin-bottom]="0"
        [bc-margin-left]="0"
        [bc-margin-right]="0"
        bc-element-type="canvas"
        [bc-display-value]="false"
        [bc-font-size]="30"
        [bc-text-margin]="-1"
      >
      </ngx-barcode>
    </div>
  </div>

  <div class="w-100 flex ph-10">
    <p class="m-0">Recebedor:</p>
    <div class="w-100" style="border-bottom: 1px solid black"></div>
  </div>

  <div class="flex justify-between ph-10 mt-10">
    <p class="m-0">Assinatura:</p>
    <div class="w-100" style="border-bottom: 1px solid black"></div>
    <p class="m-0">Documento:</p>
    <div class="w-100" style="border-bottom: 1px solid black"></div>
  </div>

  <div class="grid-2-column">
    <div>
      <div class="mt-5 ml-10">
        <p><b>Destinatário</b></p>
        <p>{{ order.detail.receiver.name.toUpperCase() }}</p>
        <p>
          {{ order.detail.receiver.address.line_1 }},
          {{ order.detail.receiver.address.number }}
        </p>
        <p *ngIf="order.detail.receiver.address.line_2">
          {{ order.detail.receiver.address.line_2 }}
        </p>
        <p>{{ order.detail.receiver.address.neighborhood }}</p>
        <p>
          {{
            order.detail.receiver.address.postal_code | mask : masks.CEP_MASK
          }}
          - {{ order.detail.receiver.address.city }} -
          {{ order.detail.receiver.address.state }}
        </p>
      </div>
    </div>

    <div>
      <div class="w-100 mt-10">
        <div class="flex column justify-start align-start ml-10">
          <ngx-barcode
            class="p-0"
            [bc-value]="order.detail.receiver.address.postal_code"
            [bc-height]="58"
            [bc-width]="1.5"
            bc-element-type="canvas"
            [bc-margin-top]="0"
            [bc-margin-bottom]="0"
            [bc-margin-left]="0"
            [bc-margin-right]="0"
          >
          </ngx-barcode>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5 ml-10">
    <p><b>Remetente</b></p>
    <p>{{ order.detail.sender.name.toUpperCase() }}</p>
    <p>
      {{ order.detail.sender.address.line_1 }},
      {{ order.detail.sender.address.number }}
    </p>
    <p *ngIf="order.detail.sender.address.line_2">
      {{ order.detail.sender.address.line_2 }}
    </p>
    <p>{{ order.detail.sender.address.neighborhood }}</p>
    <p>
      {{ order.detail.sender.address.postal_code | mask : masks.CEP_MASK }} -
      {{ order.detail.sender.address.city }} -
      {{ order.detail.sender.address.state }}
    </p>
  </div>
</div>
