<ng-container *ngIf="!isPrint()">
  <ng-container *ngIf="identity$ | async as user; else noIdentity">
    <ng-container [ngSwitch]="changeHeader()">
      <ng-container *ngSwitchCase="false">
        <nz-header nzTheme="light">
          <div class="logo clickable" (click)="goHome()">
            <div class="flex justify-center align-center">
              <img src="assets\images\svg\Vector.svg" style="width: 90%; margin-bottom: 35px;" alt="">
            </div>
          </div>
          <div class="flex justify-between align-center" style="padding-left: 2%">
            <ng-container [ngSwitch]="changeMenu()">
              <ng-container *ngSwitchCase="false">
                <app-admin-nav-links *ngIf="isAdmin" (onNavLinkClicked)="onNavLinkClickedHandle($event)"
                  [route]="route">
                </app-admin-nav-links>
                <app-client-nav-links *ngIf="!isAdmin" (onNavLinkClicked)="onNavLinkClickedHandle($event)"
                  [route]="route">
                </app-client-nav-links>
              </ng-container>
              <ng-container *ngSwitchCase="true">
                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                  <span nz-icon nzType="menu" nzTheme="outline"></span>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <app-admin-nav-links *ngIf="isAdmin" (onNavLinkClicked)="onNavLinkClickedHandle($event)"
                    [route]="route">
                  </app-admin-nav-links>
                  <app-client-nav-links *ngIf="!isAdmin" (onNavLinkClicked)="onNavLinkClickedHandle($event)"
                    [route]="route">
                  </app-client-nav-links>
                </nz-dropdown-menu>
              </ng-container>
            </ng-container>
            <ul class="menu" nz-menu nzTheme="light" nzMode="horizontal" class="header-menu">
              <li nz-menu-item [nzSelected]="false">
                <nz-input-group [nzSuffix]="suffixIconSearch">
                  <input [disabled]="loadingOrder" (keyup.Enter)="goToOrder()" [(ngModel)]="searchOrderInput"
                    nzSize="large" type="text" nz-input placeholder="Código de rastreio" />
                </nz-input-group>
                <ng-template #suffixIconSearch>
                  <span *ngIf="!loadingOrder" (click)="goToOrder()" nz-icon nzType="search"></span>
                  <span *ngIf="loadingOrder" nz-icon nzType="loading" nzTheme="outline"></span>
                </ng-template>
              </li>
              <li nz-menu-item class="highlighted" [nzSelected]="false">
                <a [routerLink]="[ '/calculadora' ]"><span nz-icon nzType="calculator"
                    nzTheme="outline"></span>&nbsp;&nbsp;&nbsp;Calc. de Fretes</a>
              </li>
              <li nz-menu-item [nzSelected]="false">
                <nz-badge [nzCount]="user?.cartItemsCount ?? 0" [nzOverflowCount]="99"
                  (click)="onNavLinkClickedHandle('/carrinho')">
                  <span nz-icon nzType="shopping-cart" nzTheme="outline" style="font-size: 20px;"></span>
                  <a class="head-example"></a>
                </nz-badge>
              </li>
              <li class="mr-10" nz-menu-item [nzSelected]="false">
                <div class="flex justify-center align-center">
                  <span nz-icon nzType="dollar" nzTheme="outline" style="font-size: 20px;"></span>
                  <ng-container *ngIf="wallet$ | async as wallet; else noBalance">
                    <p nz-typography class="paragraph-3 m-0 ml-5"
                      [nzType]="!getBalance(wallet?.balance ?? 0, user.balance || 0).positive ? 'danger' : undefined">
                      {{ getBalance(wallet?.balance ?? 0, user.balance || 0).value | currency }}
                    </p>
                  </ng-container>
                  <ng-template #noBalance>
                    <p class="paragraph-3 m-0">
                      <span nz-icon nzType="loading" nzTheme="outline"></span>
                    </p>
                  </ng-template>
                </div>
              </li>
              <li nz-menu-item [nzSelected]="false" (click)="showProfile()">
                <nz-avatar *ngIf="user?.imageUrl" nzIcon="user" style="margin-right: 10px;font-size: 12px;"
                  [nzSrc]="user?.imageUrl ?? ''">
                </nz-avatar>
                <nz-avatar *ngIf="!user?.imageUrl" style="background-color: #FF864A" [nzText]="getTextUser(user)">
                </nz-avatar>
                <app-profile-card [user]="user">
                </app-profile-card>
              </li>
            </ul>
          </div>
        </nz-header>
      </ng-container>

      <ng-container *ngSwitchCase="true">
        <nz-header nzTheme="light" class="nz-header-b">
          <button nz-button (click)="open()">
            <span nz-icon nzType="menu" nzTheme="outline"></span>
          </button>
          <nz-drawer [nzClosable]="true" [nzVisible]="visible" nzPlacement="left" (nzOnClose)="close()">
            <ng-container *nzDrawerContent>
              <nz-input-group [nzSuffix]="suffixIconSearch" class="mb-10">
                <input [disabled]="loadingOrder" (keyup.Enter)="goToOrder()" [(ngModel)]="searchOrderInput"
                  nzSize="large" type="text" nz-input placeholder="Código de rastreio" />
              </nz-input-group>
              <ng-template #suffixIconSearch>
                <span *ngIf="!loadingOrder" (click)="goToOrder()" nz-icon nzType="search"></span>
                <span *ngIf="loadingOrder" nz-icon nzType="loading" nzTheme="outline"></span>
              </ng-template>
              <app-admin-nav-links *ngIf="isAdmin" (onNavLinkClicked)="onNavLinkClickedHandle($event)" [route]="route"
                (click)="close()">
              </app-admin-nav-links>
              <app-client-nav-links *ngIf="!isAdmin" (onNavLinkClicked)="onNavLinkClickedHandle($event)" [route]="route"
                (click)="close()">
              </app-client-nav-links>
              <ul class="menu drawer-menu" nz-menu nzTheme="light" nzMode="horizontal">
                <li nz-menu-item [nzSelected]="false">
                  <span nz-icon nzType="dollar" nzTheme="outline" style="font-size: 20px; margin-right: 8px;"></span>
                  <ng-container *ngIf="wallet$ | async as wallet; else noBalance">
                    <p nz-typography class="paragraph-3 bold"
                      [nzType]="!getBalance(wallet?.balance ?? 0, user.balance || 0).positive ? 'danger' : undefined"
                      style="font-size: 18px;">
                      <span>{{getBalance(wallet?.balance ?? 0, user.balance || 0).positive ? '' : '- '}}</span>
                      {{ getBalance(wallet?.balance ?? 0, user.balance || 0).value | currency }}
                    </p>
                  </ng-container>
                  <ng-template #noBalance>
                    <p class="paragraph-3">
                      <span nz-icon nzType="loading" nzTheme="outline"></span>
                    </p>
                  </ng-template>
                </li>
                <li nz-menu-item [nzSelected]="false" (click)="showProfile()">
                  <nz-avatar *ngIf="user?.imageUrl" nzIcon="user" [nzSrc]="user?.imageUrl ?? ''" nzSize="large">
                  </nz-avatar>
                  <nz-avatar *ngIf="!user?.imageUrl" style="background-color: #FF864A" [nzText]="getTextUser(user)"
                    nzSize="large">
                  </nz-avatar>
                  <app-profile-card [user]="user">
                  </app-profile-card>
                </li>
              </ul>
            </ng-container>
          </nz-drawer>
          <img src="assets\images\svg\Vector.svg" class="logo clickable" (click)="goHome()" alt="">
          <ul class="menu header-menu " nz-menu nzTheme="light" nzMode="horizontal">
            <li nz-menu-item class="highlighted" [nzSelected]="false">
              <a [routerLink]="[ '/calculadora' ]">
                <span nz-icon nzType="calculator" nzTheme="outline" style="font-size: 20px;"></span>
              </a>
            </li>
            <li nz-menu-item [nzSelected]="false">
              <nz-badge [nzCount]="user?.cartItemsCount ?? 0" [nzOverflowCount]="99"
                (click)="onNavLinkClickedHandle('/carrinho')">
                <span nz-icon nzType="shopping-cart" nzTheme="outline" style="font-size: 20px;"></span>
                <a class="head-example"></a>
              </nz-badge>
            </li>
          </ul>
        </nz-header>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noIdentity>
    <nz-header class="flex align-center justify-center" *ngIf="!isLoginRoute()" nzTheme="light">
      <div class="logo clickable" (click)="goHome()">
        <div class="flex justify-center align-center">
          <img src="assets\images\svg\Vector.svg" style="width: 90%; margin-bottom: 35px;" alt="">
        </div>
      </div>
    </nz-header>
  </ng-template>
</ng-container>
