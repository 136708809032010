import { TagCorreiosComponent } from './../../components/tag-template/tag-correios/tag-correios.component';
import { NgxMaskModule } from 'ngx-mask';
import { TagHolderComponent } from './../../components/tag-holder/tag-holder.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagTemplateComponent } from '../../components/tag-template/tag-template.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgZorroAntdModule } from '../ng-ant-design.module';
import { JadlogContentComponent } from '../../components/content-claim/jadlog-content/jadlog-content.component';
import { BarcodeGeneratorAllModule, QRCodeGeneratorAllModule, DataMatrixGeneratorAllModule } from '@syncfusion/ej2-angular-barcode-generator';
import { CorreiosContentComponent } from '../../components/content-claim/correios-content/correios-content.component';

@NgModule({
  declarations: [
    TagHolderComponent,
    TagTemplateComponent,
    TagCorreiosComponent,
    JadlogContentComponent,
    CorreiosContentComponent
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    NgxMaskModule.forRoot(),
    NgxBarcodeModule,
    BarcodeGeneratorAllModule,
    QRCodeGeneratorAllModule,
    DataMatrixGeneratorAllModule
  ],
  exports: [
    TagTemplateComponent,
    TagCorreiosComponent,
    JadlogContentComponent,
    CorreiosContentComponent
  ],
})
export class TagModule { }
