import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'src/app/core/util/operators';
import { DATE_FORMAT } from 'src/app/config/input.constants';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { createRequestOption } from 'src/app/core/request/request-util';
import { IOrder, getOrderIdentifier } from '../order.model';
import { GetOrderDto } from 'src/app/dtos/get-order.dto';
import { Page } from 'src/app/dtos/page.dto';
import { GetQuotationDto } from 'src/app/dtos/get-quotation.dto';
import { ConferOrderDto } from 'src/app/dtos/confer-order.dto';
import { PaymentStatus } from '../../enumerations/payment-status.model';
import { ProblemFilterType } from '../../enumerations/problem-type.model';
import { GetReceiverDto } from 'src/app/dtos/get-receiver.dto';
import { GetConferDto } from 'src/app/dtos/get-confer.dto';
import { AnyARecord } from 'dns';

export type EntityResponseType = HttpResponse<IOrder>;
export type EntityArrayResponseType = HttpResponse<IOrder[]>;

@Injectable({ providedIn: 'root' })
export class OrderService {
  public resourceUrl2 =
    this.applicationConfigService.getMicroserviceSeach('order');
  public resourceUrl = this.applicationConfigService.getEndpointFor('orders');
  public resourceUrl1 = this.applicationConfigService.getEndpointConf();
  public microserviceUrl: string =
    this.applicationConfigService.getMicroservicePrefix();
  public downloadUrl: string =
    this.applicationConfigService.getEndpointDownloads();

  constructor(
    protected http: HttpClient,
    private applicationConfigService: ApplicationConfigService
  ) {}

  create(order: IOrder): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(order);
    return this.http
      .post<IOrder>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(order: IOrder): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(order);
    return this.http
      .put<IOrder>(
        `${this.resourceUrl}/${getOrderIdentifier(order) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(order: IOrder): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(order);
    return this.http
      .patch<IOrder>(
        `${this.resourceUrl}/${getOrderIdentifier(order) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  get(id: number): Observable<GetOrderDto> {
    return this.http.get<GetOrderDto>(`${this.resourceUrl}/${id}`);
  }

  shared(code: string) {
    return this.http.get<GetOrderDto>(`${this.resourceUrl}/shared/${code}`);
  }

  updateReceiver(id: number, receiver: GetReceiverDto) {
    return this.http.put<GetOrderDto>(
      `${this.resourceUrl}/${id}/receiver`,
      receiver
    );
  }

  getOrders(type?: ProblemFilterType): Observable<GetOrderDto[]> {
    let url = `${this.resourceUrl2}/problem`;
    if (type && type.toString() !== ProblemFilterType[ProblemFilterType.ALL])
      url += `?type=${type}`;
    return this.http.get<GetOrderDto[]>(url);
  }

  problem(status?: ProblemFilterType): Observable<GetOrderDto[]> {
    let url = `${this.resourceUrl}/problem`;
    if (
      status &&
      status.toString() !== ProblemFilterType[ProblemFilterType.ALL]
    )
      url += `?status=${status}`;
    return this.http.get<GetOrderDto[]>(url);
  }

  queryConfer(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any>(`${this.resourceUrl1}`, {
      params: options,
    });
  }

  updateConfer(
    conferenceId: number,
    valueToPay: number,
    paymentType: string
  ): Observable<any> {
    return this.http.put<any>(
      `${this.applicationConfigService.getEndpointFor('update', 'conference')}`,
      { conferenceId, valueToPay, paymentType }
    );
  }

  querySearch(req?: any): Observable<Page<GetOrderDto>> {
    const options = createRequestOption(req);
    return this.http.get<Page<GetOrderDto>>(this.resourceUrl2, {
      params: options,
    });
  }

  export(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any>(this.microserviceUrl + 'order/export', {
      params: options,
    });
  }

  exportConference(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any>(this.microserviceUrl + 'conference/export', {
      params: options,
    });
  }

  download(filename): Observable<any> {
    return this.http.get(this.downloadUrl + '?filename=' + filename, {
      responseType: 'blob',
    });
  }

  query(req?: any): Observable<Page<GetOrderDto>> {
    const options = createRequestOption(req);
    return this.http.get<Page<GetOrderDto>>(this.resourceUrl2, {
      params: options,
    });
  }

  queryPayment(
    status: PaymentStatus,
    req?: any
  ): Observable<Page<GetOrderDto>> {
    const options = createRequestOption(req);
    return this.http.get<Page<GetOrderDto>>(
      `${this.resourceUrl}/payments/status/${status}`,
      {
        params: options,
      }
    );
  }

  search(
    userId: number,
    trackingCode?: string,
    orderNumber?: string
  ): Observable<Page<GetOrderDto>> {
    const options = createRequestOption({
      eq: `${
        trackingCode
          ? `trackingCode(${trackingCode})`
          : `orderNumber(${orderNumber})`
      }and:userId(${userId})`,
    });
    return this.http.get<Page<GetOrderDto>>(`${this.resourceUrl}`, {
      params: options,
    });
  }

  cancel(id: number): Observable<GetOrderDto> {
    return this.http.delete<GetOrderDto>(`${this.resourceUrl}/${id}/cancel`);
  }

  adminCancel(id: number): Observable<GetOrderDto> {
    return this.http.delete<GetOrderDto>(`${this.resourceUrl}/${id}`);
  }

  requestPayment(orders: number[]) {
    return this.http.post(`${this.resourceUrl}/payments/request`, { orders });
  }

  conferOrder(id: number, confer: ConferOrderDto) {
    return this.http.patch<GetOrderDto>(
      `${this.resourceUrl}/${id}/confer`,
      confer
    );
  }

  includeOrder(id: number) {
    return this.http.patch<GetOrderDto>(
      `${this.resourceUrl}/${id}/include`,
      null
    );
  }

  addOrderToCollectionIfMissing(
    orderCollection: IOrder[],
    ...ordersToCheck: (IOrder | null | undefined)[]
  ): IOrder[] {
    const orders: IOrder[] = ordersToCheck.filter(isPresent);
    if (orders.length > 0) {
      const orderCollectionIdentifiers = orderCollection.map(
        (orderItem) => getOrderIdentifier(orderItem)!
      );
      const ordersToAdd = orders.filter((orderItem) => {
        const orderIdentifier = getOrderIdentifier(orderItem);
        if (
          orderIdentifier == null ||
          orderCollectionIdentifiers.includes(orderIdentifier)
        ) {
          return false;
        }
        orderCollectionIdentifiers.push(orderIdentifier);
        return true;
      });
      return [...ordersToAdd, ...orderCollection];
    }
    return orderCollection;
  }

  protected convertDateFromClient(order: IOrder): IOrder {
    return Object.assign({}, order, {
      createdDate: order.createdDate?.isValid()
        ? order.createdDate.format(DATE_FORMAT)
        : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate
        ? dayjs(res.body.createdDate)
        : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(
    res: EntityArrayResponseType
  ): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((order: IOrder) => {
        order.createdDate = order.createdDate
          ? dayjs(order.createdDate)
          : undefined;
      });
    }
    return res;
  }
}
