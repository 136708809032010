import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, Observable, of, switchMap } from 'rxjs';
import { Account } from 'src/app/core/auth/account.model';
import { AccountService } from 'src/app/core/auth/account.service';
import { EventManager } from 'src/app/core/util/event-manager.service';
import { OrderService } from 'src/app/entities/order/service/order.service';
import { EventType } from '../../services/event-type.constants';
import { NavigationService } from '../../services/navigation.service';
import { PubsubService } from '../../services/pubsub.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
  @ViewChild('appProfileCard') appProfileCard: any;
  cartLength?: number;
  route = '';
  isAdmin = false;

  identity$: Observable<Account | null> = of(null);
  wallet$: Observable<{ balance: number } | null> = of(null);
  searchOrderInput = '';
  loadingOrder = false;
  innerWidth!: number;
  visible = false;

  public isPartner: boolean = false;

  constructor(
    private readonly eventManager: EventManager,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly pubsubService: PubsubService,
    private readonly orderService: OrderService,
    private readonly navigationService: NavigationService,
    private readonly message: NzMessageService
  ) {
    this.isPartner = this.accountService.isPartner();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.navigationService.previousRoute = location.href;
      }
      if (event instanceof NavigationEnd) {
        this.route = event.url;
      }
    });

    eventManager.subscribe(
      EventType.authentication.logout,
      () => (this.identity$ = of(null))
    );
    eventManager.subscribe(EventType.authentication.login, () => {
      this.initiateIdentity();
      this.initiateWallet();
      this.isAdmin = this.accountService.isAdmin();
    });

    eventManager.subscribe(EventType.cart.update_cart_itens, () =>
      this.cartEventListener()
    );
  }

  ngOnInit(): void {
    this.initiateIdentity();
    this.initiateWallet();
    this.isAdmin = this.accountService.isAdmin();
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  cartEventListener() {
    this.pubsubService.publish(EventType.profile.updated);
  }

  initiateIdentity() {
    this.identity$ = this.pubsubService
      .register<Account>(EventType.profile.updated)!
      .pipe(switchMap(() => this.accountService.identity(true)));
  }

  initiateWallet() {
    this.wallet$ = this.pubsubService
      .register<{ balance: number }>(EventType.wallet.updated)!
      .pipe(switchMap(() => this.accountService.getWallet()));
  }

  async goToOrder() {
    if (this.searchOrderInput.length > 4) {
      const value = this.searchOrderInput.valueOf();
      this.searchOrderInput = '';
      this.loadingOrder = true;
      this.accountService
        .identity()
        .pipe(
          switchMap((account) => {
            return this.orderService.search(account!.id!, value).pipe(
              map((result) => {
                return result.items[0];
              })
            );
          })
        )
        .subscribe({
          next: (order) => {
            if (order) {
              if (this.isAdmin) {
                this.router.navigate([
                  '/admin',
                  'usuarios',
                  order.user.id,
                  'encomendas',
                  order.id,
                ]);
              } else {
                this.router.navigate(['/encomendas', order.id, 'detalhes']);
              }
            } else {
              this.loadingOrder = false;
              this.message.error('Encomenda não encontrada');
            }
          },
          complete: () => (this.loadingOrder = false),
          error: () => (this.loadingOrder = false),
        });
    }
  }

  getTextUser(user: Account) {
    if (!user) return '';
    return user.firstName![0] + user.lastName![0];
  }

  showProfile() {
    this.eventManager.broadcast(EventType.template.profile.card.open);
  }

  getBalance(iuguBalance: number, dyzonBalance: number) {
    if (dyzonBalance >= 0)
      return {
        value: iuguBalance,
        positive: true,
      };
    return {
      value: iuguBalance,
      positive: false,
    };
  }

  showNotification() {
    this.eventManager.broadcast(EventType.template.notification.card.open);
  }

  onNavLinkClickedHandle(path: string) {
    this.router.navigate([path]);
  }

  goHome() {
    this.router.navigate(['']);
  }

  isLoginRoute() {
    return (
      this.route === '/login' ||
      this.route === '/login/register' ||
      this.route === '/login/forget-password' ||
      this.route.includes('/print') ||
      this.route.includes('/rastreio')
    );
  }

  isPrint() {
    return this.route.includes('/print');
  }

  changeMenu(): boolean {
    return this.innerWidth < 1065;
  }

  changeHeader(): boolean {
    return this.innerWidth < 850;
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
}
