import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-admin-nav-links',
  templateUrl: './admin-nav-links.component.html',
  styleUrls: ['./admin-nav-links.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminNavLinksComponent implements OnInit {
  @Input('route') route: string = '/admin/dashboard';
  @Output('onNavLinkClicked') onNavLinkClicked = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    this.route = '/admin/dashboard';
  }

  navigate(path: string) {
    this.onNavLinkClicked.emit('admin/' + path);
  }
}
