import { GetItemDto } from './../../../../dtos/get-item.dto';
import { MaskConstants } from './../../../constants/mask.constants';
import { GetOrderDto } from './../../../../dtos/get-order.dto';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-jadlog-content',
  templateUrl: './jadlog-content.component.html',
  styleUrls: ['./jadlog-content.component.scss']
})
export class JadlogContentComponent implements OnInit {

  @Input('order') order!: GetOrderDto;
  masks = MaskConstants;

  item1?: GetItemDto;
  item2?: GetItemDto;
  item3?: GetItemDto;
  item4?: GetItemDto;
  item5?: GetItemDto;
  item6?: GetItemDto;
  item7?: GetItemDto;
  item8?: GetItemDto;
  item9?: GetItemDto;
  item10?: GetItemDto;

  ngOnInit(): void {
    this.populateItems();
  }

  populateItems() {
    this.order.detail.pack.items?.slice(0, 9).forEach((item, index) => {
      if (item) {
        switch (index) {
          case 0: this.item1 = item;
            break;

          case 1: this.item2 = item;
            break;

          case 2: this.item3 = item;
            break;

          case 3: this.item4 = item;
            break;

          case 4: this.item5 = item;
            break;

          case 5: this.item6 = item;
            break;

          case 6: this.item7 = item;
            break;

          case 7: this.item8 = item;
            break;

          case 8: this.item9 = item;
            break;

          case 9: this.item10 = item;
            break;
        }
      }
    });
  }

  getDate() {
    return new Date();
  }

}
